import React from 'react';
import { Box } from '@mui/material';
import AlbumIcon from '@mui/icons-material/Album';
import sleeveImage from './images/sleeve.svg';
import { colors } from '../entrypoints/lib/theme';

const AlbumArt = ({ imageUrl, title, canEdit, size = 200 }) => {
  return (
    <Box
      sx={{
        width: size,
        height: size,
        borderRadius: '10px',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      {/* Sleeve image */}
      <img
        src={sleeveImage}
        alt="Album sleeve"
        style={{
          position: 'absolute',
          top: '2%',
          left: '2%',
          width: '96%',
          height: '96%',
          objectFit: 'contain',
        }}
      />

      {/* Album image or icon */}
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '45%',
          transform: 'translate(-50%, -50%)',
          width: '70%',
          height: '70%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          borderRadius: '100%',
        }}
      >
        {imageUrl ? (
          <img
            src={imageUrl}
            alt={title}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        ) : !canEdit && (
          <AlbumIcon
            sx={{
              fontSize: size * 0.75,
              color: colors.lightGray,
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default AlbumArt;
